import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import { useRef, useEffect } from "react";
import raf from "rc-util/lib/raf";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedHours } from "../redux/shadingSystem";
import { generateTimeArray } from "./utils";

const HandleTooltip = ({
  value,
  children,
  visible,
  tipFormatter = (val) => `${val}`,
  ...restProps
}) => {
  const tooltipRef = useRef(null);
  const rafRef = useRef(null);

  const cancelKeepAlign = () => {
    raf.cancel(rafRef.current);
  };

  const keepAlign = () => {
    rafRef.current = raf(() => {
      tooltipRef.current?.forceAlign();
    });
  };

  useEffect(() => {
    if (visible) {
      keepAlign();
    } else {
      cancelKeepAlign();
    }

    return cancelKeepAlign;
  }, [value, visible]);

  return (
    <Tooltip
      placement="top"
      overlay={tipFormatter(value)}
      overlayInnerStyle={{ minHeight: "auto" }}
      ref={tooltipRef}
      visible={visible}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};

const handleRender = (node, props, dates) => (
  <HandleTooltip
    value={props.value}
    visible={props.dragging}
    tipFormatter={(value) => dates[value]}
  >
    {node}
  </HandleTooltip>
);

const DateTimeSlider = ({ dates, selectedIndex = 0, onChange }) => {
  const handleChange = (selectedIndex) => {
    onChange(selectedIndex);
  };
  var allProejctParameters = useSelector((state) => state.sceneVar);
  const shadingProgressionStep = allProejctParameters.shadingProgressionStep

  return (
    <div style={{ margin: "20px", position: "relative" }}>
      <p
        style={{
          position: "absolute",
          left: "50%",
          top: "-30px",
          transform: "translateX(-50%)",
        }}
      >
        Today {dates[selectedIndex]?.toLocaleString()}
      </p>

      <Slider
        min={0}
        max={dates.length - 1}
        value={selectedIndex}
        onChange={handleChange}
        step={1}
        handleRender={(node, props) => handleRender(node, props, dates)}
        marks={{
          0: dates[0]?.toLocaleString(),
          [12 * (15 / shadingProgressionStep)]: dates[12 * (15 / shadingProgressionStep)]?.toLocaleString(),
          [24 * (15 / shadingProgressionStep)]: dates[24 * (15 / shadingProgressionStep)]?.toLocaleString(),
          [36 * (15 / shadingProgressionStep)]: dates[36 * (15 / shadingProgressionStep)]?.toLocaleString(),
          [48 * (15 / shadingProgressionStep)]: dates[48 * (15 / shadingProgressionStep)]?.toLocaleString(),
          [60 * (15 / shadingProgressionStep)]: dates[60 * (15 / shadingProgressionStep)]?.toLocaleString(),
          [72 * (15 / shadingProgressionStep)]: dates[72 * (15 / shadingProgressionStep)]?.toLocaleString(),
          [84 * (15 / shadingProgressionStep)]: dates[84 * (15 / shadingProgressionStep)]?.toLocaleString(),
          [dates.length - 1]: {
            style: {
              width: "max-content",
            },
            label: dates[dates.length - 1].toLocaleString(),
          },
        }}
      />
    </div>
  );
};

const ShadingProgressionSelector = () => {
  var shadingDemandSelector = useSelector((state) => state.shadingSystem.value);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  // const newProjectId = localStorage.getItem("project_id");
  // const project_id = getOldProjectId(newProjectId)

  const dispatch = useDispatch();

  var allProejctParameters = useSelector((state) => state.sceneVar);
  const shadingProgressionStep = allProejctParameters.shadingProgressionStep
  const shadingProgressionDate = allProejctParameters.shadingProgressionDate
  const dateTimes = generateTimeArray(shadingProgressionDate, shadingProgressionStep)

  const hasShadingProgression = allProejctParameters?.shadingProgression

  if (!hasShadingProgression) return;
  // const data = getRelavantHardcodedData(project_id, newProjectId);

  // const dateTimes = Object.keys(data[0] ?? {})?.filter(
  //   (key) => key !== "ID" && key !== "polygon"
  // );

  // const projectDateTimes = project_id != 8 ? dateTimesOfficeFirst : dateTimes;
  const projectDateTimes = dateTimes;
  if (shadingDemandSelector.checkStatus[2])
    return (
      <div
        style={{
          width: "70%",
          position: "fixed",
          padding: "16px 24px",
          bottom: 16,
          transform: "translateX(-50%)",
          left: "50%",
          zIndex: 101,
          backgroundColor: "white",
          border: "1px solid #ddd",
          borderRadius: "8px",
        }}
      >
        <DateTimeSlider
          dates={projectDateTimes.map((datetime) => datetime.split(" ")[1])}
          selectedIndex={selectedIndex}
          onChange={(selectedIndex) => {
            setSelectedIndex(selectedIndex);
            dispatch(setSelectedHours(dateTimes[selectedIndex]));
          }}
        />
      </div>
    );
};

export default ShadingProgressionSelector;
