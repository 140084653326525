import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as THREE from "three";
// import Project15_29SunPosition from "../rawDataNotImplemented/Project15_29SunPosition";
// import Project8SunPosition from "../rawDataNotImplemented/Project8SunPosition";
// import Project52SunPosition from "../rawDataNotImplemented/Project52SunPosition";
// import Project50SunPosition from "../rawDataNotImplemented/Project50SunPosition";
// import Project56SunPosition from "../rawDataNotImplemented/Project56SunPosition";
// import Project3SunPosition from "../rawDataNotImplemented/Project3SunPosition";
// import Project376SunPosition from "../rawDataNotImplemented/Project376SunPosition";
// import Project1289SunPosition from "../rawDataNotImplemented/Project1289SunPosition";

import { getOldProjectId } from "../utility/hardconding";
import axios from "axios";
import { setSunMovementIsLoading } from "../../redux/shadingSystem";
import { reduxLocalStorage } from "../utility/storage";

// export const getRelavantHardcodedSunPositionData = (projectId, newProjectId) => {
//   if (projectId == 8) return Project8SunPosition;
//   if (projectId == 52) return Project52SunPosition;
//   if (projectId == 15 || projectId == 29) return Project15_29SunPosition;
//   if (projectId == 50) return Project50SunPosition;
//   if (projectId == 56) return Project56SunPosition;
//   if (projectId == 3) return Project3SunPosition;
//   if (newProjectId == 376) return Project376SunPosition
//   if (newProjectId == 1289) return Project1289SunPosition
//   return [];
// };

function calculateSunPosition(elevation, azimuth, radius) {
  const elevationRad = THREE.MathUtils.degToRad(elevation);
  const azimuthRad = THREE.MathUtils.degToRad(azimuth);

  const z = -radius * Math.cos(elevationRad) * Math.cos(azimuthRad);
  const x = radius * Math.cos(elevationRad) * Math.sin(azimuthRad);
  const y = radius * Math.sin(elevationRad);

  return { x, y, z };
}

const Sunlight = () => {
  const token = localStorage.getItem("token");
  const lightRef = useRef();
  // const [data, setData] = useState([])
  // const [dataRevceived, setDataReceived] = useState(false)

  // const shadingDate = useSelector((state) => state.shadingSystem.shadingDate);
  const selectedHours = useSelector(
    (state) => state.shadingSystem.selectedHours
  );


  var shadingDemandSelector = useSelector((state) => state.shadingSystem.value);
  const project_id = reduxLocalStorage.getItem("project_id");
  // const oldProjectId = getOldProjectId(project_id)
  const isVisible = shadingDemandSelector.checkStatus[2];
  const selectedTime = new Date(selectedHours);
  const hour = selectedTime.getHours();
  const minute = selectedTime.getMinutes();


  const [resultArray, setResultArray] = useState([]);
  const [success, setSuccess] = useState(false)
  const visible = shadingDemandSelector.checkStatus[2]

  const dispatch = useDispatch()
  var allProejctParameters = useSelector((state) => state.sceneVar);
  const shadingProgressionStep = allProejctParameters.shadingProgressionStep
  const shadingProgressionDate = allProejctParameters.shadingProgressionDate

  useEffect(() => {
    if (visible && !success && shadingProgressionDate && shadingProgressionStep) {
      dispatch(setSunMovementIsLoading(true));

      const [_, month, day] = shadingProgressionDate?.split('-')
      const timeFrom = `${month}-${day} 00:00`
      const timeTo = `${month}-${day} 23:59`

      let url = `${process.env.REACT_APP_API_URL_2}api/weather/sun-movements?projectId=${project_id}&timeFrom=${timeFrom}&timeTo=${timeTo}&limit=50000`

      axios.get(url, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setSuccess(true)
        setResultArray(response.data?.list);
      }).finally(() => {
        dispatch(setSunMovementIsLoading(false));
      })
    }
  }, [visible]);


  if (!resultArray?.length) return
  let selectedSunPosition = resultArray?.find((e) => {
    const time = new Date(e.time);
    return hour === time.getHours() && minute === time.getMinutes();
  });

  if (!selectedSunPosition)
    selectedSunPosition = resultArray?.find((e) => {
      const time = new Date(e.time);
      return hour === time.getHours() && Math.abs(minute - time.getMinutes()) < 15;
    });


  const { elevation, azimuth } = selectedSunPosition || {
    elevation: null,
    azimuth: null,
  };
  const { x, y, z } = calculateSunPosition(elevation, azimuth, 350);
  const d = 350;
  if (lightRef.current) {
    const light = lightRef.current;


    // DON'T REMOVE LINE BELOW! OTHERWISE SHADOWS DISAPPEAR!!
    const helper = new THREE.CameraHelper(light.shadow.camera);
    light.shadow.camera.left = -d;
    light.shadow.camera.right = d;
    light.shadow.camera.top = d / 2;
    light.shadow.camera.bottom = -d / 2;
  }

  // useEffect(() => {
  //   if (isVisible && !dataRevceived) {
  //     // const url = `${process.env.REACT_APP_API_URL}api/weather/sun-movements?limit=10000&project_id=${project_id}
  //     // timeFrom='07-30 10:00'&timeTo='07-30 11:00'`

  //     const url = `${process.env.REACT_APP_API_URL}api/weather/sun-movements?limit=10000&projectId=${project_id}&timeFrom=07-30 10:00&timeTo=07-30 11:00`

  //     axios.get(url, {
  //       headers: {
  //         "Content-Type": "application/json; charset=UTF-8",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     }).then(data => {
  //       setDataReceived(true)
  //       setData(data)
  //     })
  //   }
  // }, [isVisible])

  return (
    <>
      <>
        <directionalLight
          intensity={3}
          ref={lightRef}
          position={[x, y, z]}
          color="white"
          castShadow
          visible={isVisible && elevation > 0}
        />
        {isVisible && elevation > 0 && (
          <mesh position={[x, y, z]}>
            <sphereBufferGeometry args={[6]} />
            <meshStandardMaterial color="yellow" />
          </mesh>
        )}
      </>
    </>
  );
};

export default Sunlight;
